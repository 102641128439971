import { useSelector } from 'react-redux'
import useGetCurrentTOP from '../../hooks/useGetCurrentTOP'
import props from '../../../../../../../redux/props'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import useGetTOPAbstimmung from '../../hooks/useGetTOPAbstimmung'
import { fill } from '../../../../../../../utility'
import Textarea from '../../../../../../UI/Textarea/Textarea'
import useProtokoll from '../../hooks/useProtokoll'
import Files from './Files'
import Mehrheit from './Mehrheit'
import { ModerationState, moderationStateProp } from '../../../../../../../types/Versammlung/Moderation'
import { TagesordnungspunktType } from '../../../../../../../types/Versammlung/Versammlung'

const Detail = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const moderationState: ModerationState = useSelector((state) => state[moderationStateProp])
  const currentTop = useGetCurrentTOP()
  const abstimmung = useGetTOPAbstimmung()
  const { protokoll, setProtokoll } = useProtokoll()
  if (!moderationState || !currentTop) return <div></div>
  const { top } = currentTop

  return (
    <div>
      <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-blue w-full">{t.versammlung.moderate.detail.title}</div>
      <div className="flex flex-row mt-2 gap-3">
        <div className="flex flex-col gap-2">
          <div className="h-16 aspect-square rounded-full flex items-center justify-center text-xl font-bold text-white bg-blue">
            {fill((moderationState.activeTOP as number) + 1)}
          </div>
          {!!abstimmung && top.type === TagesordnungspunktType.VOTING_DEFAULT && (
            <>
              {abstimmung.state.zustimmung ? (
                <CheckCircleIcon className="fill-green h-16 cursor-pointer hover:fill-green" />
              ) : (
                <XCircleIcon className="fill-red h-16 cursor-pointer hover:fill-red" />
              )}
            </>
          )}
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="font-bold text-lg mt-4">{top.beschreibung}</div>
          {top.type !== TagesordnungspunktType.INFO && <div className="whitespace-pre-wrap">{top.beschlussvorlage}</div>}
          {!moderationState.versammlung.wirdExternProtokolliert && (
            <div>
              <Textarea label placeholder={t.versammlung.attributes.protokoll} value={protokoll} onChange={setProtokoll} />
            </div>
          )}
          <Files currentTop={currentTop.top} />
          {abstimmung && <Mehrheit top={top} abstimmung={abstimmung} />}
        </div>
      </div>
    </div>
  )
}

export default Detail
