import { Beteiligung, BeteiligungType } from '../../../types/Beteiligung'
import { formatEUR, formatNumber } from '../../../utility'
import Upsert from './Upsert'
import { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
type Props = {
  beteiligung: Beteiligung
  callback: (beteiligung: Beteiligung) => void
  pullList: () => void
  disabled: boolean
}

const ListItem: React.FC<Props> = ({ beteiligung, callback, pullList, disabled }) => {
  const [showUebertragungen, setShowUebertragungen] = useState<boolean>(false)

  const toggle = () => setShowUebertragungen(!showUebertragungen)

  const hasUebertragungen = Array.isArray(beteiligung.uebertragungen) && beteiligung.uebertragungen.length > 0

  return (
    <div>
      <div className="flex flex-row gap-3 w-full">
        <div className="flex-1 shrink-0 truncate flex flex-row gap-1 items-center">
          {hasUebertragungen && (
            <div className="cursor-pointer" onClick={toggle}>
              {showUebertragungen ? <ChevronUpIcon className="w-5 shrink-0" /> : <ChevronDownIcon className="w-5 shrink-0" />}
            </div>
          )}
          {beteiligung.targetCompany.base.name}
        </div>
        <div className="flex-1 shrink-0 truncate">{formatEUR(beteiligung.beteiligungsHoehe)}</div>
        <div className="flex-1 shrink-0 truncate">{formatNumber(beteiligung.stimmen)}</div>
        {!disabled && (
          <div className="w-20 shrink-0 truncate">
            <Upsert
              originUUID={beteiligung.originUUID}
              originType={beteiligung.originType}
              initData={beteiligung}
              callback={callback}
              pullList={pullList}
            />
          </div>
        )}
      </div>
      {hasUebertragungen && showUebertragungen && (
        <div className="flex flex-row gap-3 w-full">
          {beteiligung.uebertragungen.map((uebertragung, index) => (
            <div key={index} className="flex flex-row gap-3 w-full">
              {uebertragung.originType === BeteiligungType.PERSON && (
                <div className="flex-1 shrink-0 truncate">
                  <span className="pl-[13px]">&bull;{` ${uebertragung.origin.kontakt.vorname} ${uebertragung.origin.kontakt.nachname}`}</span>
                </div>
              )}
              {uebertragung.originType === BeteiligungType.COMPANY && (
                <div className="flex-1 shrink-0 truncate">
                  <span className="pl-[13px]">&bull;{` ${uebertragung.origin.base.name}`}</span>
                </div>
              )}
              <div className="flex-1 shrink-0 truncate">{formatEUR(uebertragung.beteiligungsHoehe)}</div>
              <div className="flex-1 shrink-0 truncate">{formatNumber(uebertragung.stimmen)}</div>
              {!disabled && <div className="w-20 shrink-0 truncate"></div>}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ListItem
