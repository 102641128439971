import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import props from '../../../redux/props'
import Button from '../Button/Button'
import Modal from '../Modal/Modal'
import { Person } from '../../../types/Person'
import Routes from '../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import Checkbox from '../Checkbox/Checkbox'
import Loading from '../Loading/Loading'

type Props = {
  title: string
  hint?: string
  data: string[]
  set: (list: string[]) => void
  companyUUID: string
  readOnly?: boolean
  enableMail?: boolean
}

const SimpleInternalPersonList: React.FC<Props> = ({ title, hint, data = [], set, companyUUID, readOnly = false, enableMail = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [personList, setPersonList] = useState<Person[]>(null)
  const [open, setOpen] = useState<boolean>(false)
  const list = functional.use(Routes.PERSON_LIST)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const pull = async () => {
      setLoading(true)
      const result: Person[] = await list({ criteria: { targetCompanyUUID: companyUUID } })

      setLoading(false)
      if (Array.isArray(result)) {
        setPersonList(result)
      }
    }
    if (!personList && !loading) {
      pull()
    }
  }, [companyUUID, personList, list, loading])

  const getPerson = (personUUID: string) => {
    const person = personList.find((person) => person.uuid === personUUID)
    return person
  }

  const getName = (personUUID: string) => {
    const person = getPerson(personUUID)
    return `${t.attributes.anspracheItems[person.kontakt.ansprache]} ${person.kontakt.vorname || ''} ${person.kontakt.nachname || ''}`
  }

  const getMail = (personUUID: string) => {
    const person = getPerson(personUUID)
    return person.kontakt.mail
  }

  const remove = (index: number) => () => {
    set(data.filter((_, i) => i !== index))
  }

  const toggle = () => setOpen(!open)

  const confirm = () => {
    set(data)
    toggle()
  }

  const toggleContact = (personUUID: string) => () => {
    set(data.includes(personUUID) ? data.filter((uuid) => uuid !== personUUID) : [...data, personUUID])
  }

  const filterUUIDList = (uuid: string) => {
    return personList.some((person) => person.uuid === uuid)
  }

  return (
    <div>
      <label className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">{title}</label>
      <Loading loading={personList} />
      {!!hint && <div className="text-sm">{hint}</div>}
      {Array.isArray(personList) && (
        <div className="flex flex-col gap-1 mt-3">
          {data.filter(filterUUIDList).map((personUUID, i) => (
            <div key={i} className="flex flex-row justify-between gap-1 items-center">
              <div>
                <div className="truncate">{getName(personUUID)}</div>
                {enableMail && <div className="truncate">{getMail(personUUID)}</div>}
              </div>
              <div className="flex flex-row gap-3">
                <div className="cursor-pointer" onClick={remove(i)}>
                  <XMarkIcon className="w-6" />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {!readOnly && (
        <div className="flex justify-end gap-3 pt-2">
          <Button small text={t.UI.simpleInternalPersonList.add} onClick={toggle} />
        </div>
      )}
      <Modal onConfirm={confirm} show={open} onClose={toggle} title={title}>
        <div className="flex flex-col gap-2 max-h-[500px] overflow-y-auto overflow-x-hidden">
          {Array.isArray(personList) &&
            personList.map((p) => (
              <div key={p.uuid}>
                <Checkbox
                  checked={data.includes(p.uuid)}
                  onChange={toggleContact(p.uuid)}
                  label={`${t.attributes.anspracheItems[p.kontakt.ansprache]} ${p.kontakt.vorname} ${p.kontakt.nachname}`}
                />
              </div>
            ))}
        </div>
      </Modal>
    </div>
  )
}

export default SimpleInternalPersonList
