import { useState } from 'react'
import { Uebertragung, UebertragungArt } from '../../../types/Uebertragung'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import props from '../../../redux/props'
import { functional } from '@think-internet/zeus-frontend-package'
import { useSelector } from 'react-redux'
import Input from '../Input/Input'
import Currency from '../Input/Currency'
import Number from '../Input/Number'
import Toggle from '../Toggle/Toggle'
import AddFiles from '../AddFiles/AddFiles'
import { UPLOAD_TYPES } from '../DirectFileUpload/DirectFileUpload'
import { File } from '../../../types/generic'
import useToast, { Type } from '../../hooks/useToast'
import { formatEUR, formatNumber } from '../../../utility'
import BeteiligungSelect from '../BeteiligungSelect/BeteiligungSelect'
import Routes from '../../../redux/routes'
import CompanySelect from '../CompanySelect/CompanySelect'
import PersonSelect from '../PersonSelect/PersonSelect'
import OptionSelect from '../OptionSelect/OptionSelect'
import { Company } from '../../../types/Company'
import { Person } from '../../../types/Person'
import { BeteiligungType } from '../../../types/Beteiligung'
import Dropdown from '../Dropdown/Dropdown'

type Props = {
  originType: BeteiligungType
  originUUID: string
  callback: (uebertragung: Uebertragung) => void
}

type InternalUebertragung = Uebertragung & { confirmation?: boolean }

const Add: React.FC<Props> = ({ originType, originUUID, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<InternalUebertragung>({
    originType,
    originUUID,
  } as InternalUebertragung)
  const [open, setOpen] = useState<boolean>(false)
  const uebertragungCreate = functional.use(Routes.UEBERTRAGUNG_CREATE)
  const toast = useToast()
  const [targetType, setTargetType] = useState<BeteiligungType>()

  const confirm = async () => {
    if (data.sourceBeteiligung) {
      if (data.targetUUID) {
        const result = await uebertragungCreate({ data: { ...data, targetType: targetType } })
        if (result) {
          callback(result)
          toast(t.UI.uebertragungManager.add.success, Type.SUCCESS)
          setOpen(false)
        } else {
          toast(t.UI.uebertragungManager.add.error.generic, Type.ERROR)
        }
      } else {
        toast(t.UI.uebertragungManager.add.error.targetMissing, Type.ERROR)
      }
    } else {
      toast(t.UI.uebertragungManager.add.error.originBeteiligungMissing, Type.ERROR)
    }
  }
  const toggle = () => setOpen(!open)

  const set = (prop: keyof InternalUebertragung) => (value: any) => {
    setData({ ...data, [prop]: value })
  }

  const setList = (listProp: keyof Uebertragung) => (selectedList: any[]) => {
    if (selectedList.length > 0) {
      setData({ ...data, [`${listProp}UUID`]: selectedList[0].uuid, [listProp]: selectedList[0] })
    } else {
      setData({ ...data, [`${listProp}UUID`]: null, [listProp]: null })
    }
  }

  const removeFile = (file: File) => () => {
    const newFiles = data.files.filter((f) => f.key !== file.key)
    set('files')(newFiles)
  }

  const updateTargetType = (value: BeteiligungType) => {
    setTargetType(value)
    setData({ ...data, targetUUID: null, target: null })
  }

  return (
    <>
      <Button onClick={toggle} text={t.UI.uebertragungManager.add.cta} />
      <Modal large onConfirm={confirm} show={open} onClose={toggle} title={t.UI.uebertragungManager.add.createTitle}>
        <div className="flex flex-col gap-3">
          <BeteiligungSelect
            single
            criteria={{ originUUID }}
            selected={data.sourceBeteiligung ? [data.sourceBeteiligung] : []}
            onSelect={setList('sourceBeteiligung')}
          />
          {data.sourceBeteiligung && (
            <div>
              <div>{t.UI.uebertragungManager.add.targetTitle}</div>
              <OptionSelect
                options={[
                  { name: t.UI.uebertragungManager.add.targetCompany, value: BeteiligungType.COMPANY },
                  { name: t.UI.uebertragungManager.add.targetPerson, value: BeteiligungType.PERSON },
                ]}
                selected={targetType}
                onSelect={updateTargetType}
              />
              <div className="mt-3">
                {targetType === BeteiligungType.COMPANY && (
                  <CompanySelect single criteria={{}} selected={data.target ? [data.target as Company] : []} onSelect={setList('target')} />
                )}
                {targetType === BeteiligungType.PERSON && (
                  <PersonSelect single criteria={{}} selected={data.target ? [data.target as Person] : []} onSelect={setList('target')} />
                )}
              </div>
            </div>
          )}
          {data.target && (
            <>
              <Currency
                label
                placeholder={`${t.attributes.beteiligungsHoehe} (max. ${formatEUR(data.sourceBeteiligung.beteiligungsHoehe)})`}
                value={data.beteiligungsHoehe}
                onChange={set('beteiligungsHoehe')}
                max={data.sourceBeteiligung.beteiligungsHoehe}
                required
              />
              <Number
                label
                placeholder={`${t.attributes.stimmen} (max. ${formatNumber(data.sourceBeteiligung.stimmen)})`}
                value={data.stimmen}
                onChange={set('stimmen')}
                max={data.sourceBeteiligung.stimmen}
                required
              />
              <Input
                type="date"
                required
                label
                placeholder={t.attributes.anfangszeitpunkt}
                value={data.anfangszeitpunkt}
                onChange={set('anfangszeitpunkt')}
              />
              <Dropdown
                required
                label={t.UI.uebertragungManager.add.art.title}
                items={Object.values(UebertragungArt).map((art) => ({ label: t.UI.uebertragungManager.add.art.items[art], value: art }))}
                value={data.art}
                onChange={set('art')}
              />
              <AddFiles
                id={originUUID}
                removeCallback={removeFile}
                uploadType={UPLOAD_TYPES.UEBERTRAGUNG}
                files={data.files}
                onFiles={set('files')}
              />
              <Toggle required label={t.UI.uebertragungManager.add.checkHint} value={data.confirmation} onChange={set('confirmation')} />
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Add
