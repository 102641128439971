import { functional } from '@think-internet/zeus-frontend-package'
import { useParams } from 'react-router-dom'
import Routes from '../../../../redux/routes'
import { useEffect, useState } from 'react'
import Loading from '../../../UI/Loading/Loading'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import Container from '../../../UI/Container/Container'

enum Status {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

const Freigabe = () => {
  const { versammlungUUID, receiverUUID } = useParams()
  const sendFreigabeFeedback = functional.use(Routes.VERSAMMLUNG_FREIGABE_FEEDBACK)
  const [status, setStatus] = useState<Status>(Status.PENDING)
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    const runValidation = async () => {
      const status = await sendFreigabeFeedback({ versammlungUUID, receiverUUID })
      setStatus(status ? Status.SUCCESS : Status.FAILED)
    }
    if (status === Status.PENDING) {
      runValidation()
    }
  }, [versammlungUUID, receiverUUID, status, sendFreigabeFeedback])

  return (
    <div className="pt-10">
      <Container>
        {status === Status.SUCCESS && <div className="text-center w-full md:w-6/12 mx-auto">{t.versammlung.freigabe.success}</div>}
        {status === Status.FAILED && <div className="text-center w-full md:w-6/12 mx-auto text-red">{t.versammlung.freigabe.error}</div>}
        {status === Status.PENDING && (
          <div>
            <Loading loading={false} />
          </div>
        )}
      </Container>
    </div>
  )
}

export default Freigabe
