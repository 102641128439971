import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../Upsert'
import { formatEUR, formatNumber } from '../../../../../utility'
import useListGesellschafter from '../../../../hooks/useListGesellschafter'
import { BeteiligungType, Position } from '../../../../../types/Beteiligung'

const Overview: React.FC<SectionProps<void>> = ({ company }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const beteiligungen = useListGesellschafter(company.uuid)

  const getGeschaeftsfuehrer = () => {
    if (!beteiligungen) return []
    return beteiligungen.filter((b) => b.position === Position.GESCHAEFTSFUEHRER)
  }

  const getBeiraete = () => {
    if (!beteiligungen) return []
    return beteiligungen.filter((b) => b.position === Position.BEIRAT)
  }

  return (
    <div>
      <div className="flex justify-between">
        <div>{t.company.gesellschafter.kapitalPrefix}</div>
        <div>
          {formatEUR(company?.dynamic?.kapitalVerwendet)}
          {t.company.gesellschafter.usedOf}
          {formatEUR(company?.dynamic?.kapitalGesamt)}
        </div>
      </div>
      <div className="flex justify-between">
        <div>{t.company.gesellschafter.stimmenPrefix}</div>
        <div>
          {formatNumber(company?.dynamic?.stimmenVerwendet)}
          {t.company.gesellschafter.usedOf}
          {formatNumber(company?.dynamic?.stimmenGesamt)}
        </div>
      </div>
      {getGeschaeftsfuehrer().length > 0 && (
        <div className="mt-2">
          <div>{t.company.overview.geschaeftsfuehrer}</div>
          <div className="flex flex-col gap-1">
            {getGeschaeftsfuehrer().map((g) => {
              if (g.originType === BeteiligungType.PERSON) {
                return <div key={g.uuid}>{`${g.origin.kontakt.vorname} ${g.origin.kontakt.nachname} (${g.origin.kontakt.mail})`}</div>
              } else {
                return <div key={g.uuid}>{`${g.origin.base.name} (${g.origin.base.mail})`}</div>
              }
            })}
          </div>
        </div>
      )}
      {getBeiraete().length > 0 && (
        <div className="mt-2">
          <div>{t.company.overview.beiraete}</div>
          <div className="flex flex-col gap-1">
            {getBeiraete().map((g) => {
              if (g.originType === BeteiligungType.PERSON) {
                return <div key={g.uuid}>{`${g.origin.kontakt.vorname} ${g.origin.kontakt.nachname} (${g.origin.kontakt.mail})`}</div>
              } else {
                return <div key={g.uuid}>{`${g.origin.base.name} (${g.origin.base.mail})`}</div>
              }
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default Overview
