import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Beteiligung } from '../../../types/Beteiligung'
import ListItem from './ListItem'

type Props = {
  beteiligungen: Beteiligung[]
  callback: (beteiligung: Beteiligung) => void
  pullList: () => void
  disabled?: boolean
}

const List: React.FC<Props> = ({ beteiligungen, callback, pullList, disabled = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div>
      <div className="flex flex-row gap-3 w-full">
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.name}</label>
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.beteiligungsHoehe}</label>
        <label className="w-1/3 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.stimmen}</label>
        {!disabled && <div className="w-20 shrink-0 truncate"></div>}
      </div>
      <div className="flex flex-col gap-1">
        {Array.isArray(beteiligungen) && beteiligungen.length > 0 ? (
          beteiligungen.map((beteiligung, index) => (
            <ListItem key={index} beteiligung={beteiligung} callback={callback} pullList={pullList} disabled={disabled} />
          ))
        ) : (
          <div className="mt-2 text-center text-sm">{t.UI.beteiligungManager.list.empty}</div>
        )}
      </div>
    </div>
  )
}

export default List
