import { Route, Routes } from 'react-router-dom'
import Signature from './Signature/Signature'
import Freigabe from './Freigabe/Freigabe'

const Versammlung = () => {
  return (
    <Routes>
      <Route path={'/signature/:versammlungUUID/:receiverUUID'} element={<Signature />} />
      <Route path={'/freigabe/:versammlungUUID/:receiverUUID'} element={<Freigabe />} />
    </Routes>
  )
}

export default Versammlung
