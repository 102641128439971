import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { SectionProps } from '../Upsert'
import useListGesellschafter from '../../../../hooks/useListGesellschafter'
import { formatEUR, formatNumber } from '../../../../../utility'
import { ArrowTopRightOnSquareIcon, BuildingOffice2Icon } from '@heroicons/react/24/outline'
import { UserGroupIcon } from '@heroicons/react/24/solid'
import Input from '../../../../UI/Input/Input'
import { useState } from 'react'
import { Beteiligung, BeteiligungType } from '../../../../../types/Beteiligung'

const Gesellschafter: React.FC<SectionProps<void>> = ({ company }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const gesellschafter = useListGesellschafter(company.uuid)
  const [search, setSearch] = useState<string>('')

  const Wrapper = ({ children }) => <div className="py-1 px-2 rounded-full bg-turquoise w-full flex justify-between">{children}</div>

  const filter = (beteiligung: Beteiligung) => {
    if (beteiligung.originType === BeteiligungType.PERSON) {
      const person = beteiligung.origin
      const textFilter =
        person.kontakt.vorname.toLowerCase().includes(search.toLowerCase()) || person.kontakt.nachname.toLowerCase().includes(search.toLowerCase())
      return textFilter
    } else if (beteiligung.originType === BeteiligungType.COMPANY) {
      const company = beteiligung.origin
      const textFilter = company.base.name.toLowerCase().includes(search.toLowerCase())
      return textFilter
    }
    return true
  }

  if (!gesellschafter) return <></>
  return (
    <div>
      <div>
        <div className="flex justify-between">
          <div>{t.company.gesellschafter.kapitalPrefix}</div>
          <div>
            {formatEUR(company.dynamic.kapitalVerwendet)}
            {t.company.gesellschafter.usedOf}
            {formatEUR(company.dynamic.kapitalGesamt)}
          </div>
        </div>
        <div className="flex justify-between">
          <div>{t.company.gesellschafter.stimmenPrefix}</div>
          <div>
            {formatNumber(company.dynamic.stimmenVerwendet)}
            {t.company.gesellschafter.usedOf}
            {formatNumber(company.dynamic.stimmenGesamt)}
          </div>
        </div>
      </div>
      <Input placeholder={t.person.list.search} onChange={setSearch} value={search} className="mt-3" />
      <div className="flex flex-col flex-wrap gap-1 mt-2">
        {gesellschafter.filter(filter).map((g) => {
          if (g.originType === BeteiligungType.PERSON) {
            return (
              <Wrapper key={g.uuid}>
                <div className="text-white flex items-center flex-row gap-1">
                  <UserGroupIcon className="fill-white w-5" />
                  {`${g.origin.kontakt.vorname} ${g.origin.kontakt.nachname}`}
                </div>
                <div className="flex gap-3 text-white items-center">
                  {`${formatEUR(g.beteiligungsHoehe)} | ${t.company.gesellschafter.stimmenPrefix}${formatNumber(g.stimmen)}`}
                  <a href={`/admin/person/${g.origin.uuid}`}>
                    <ArrowTopRightOnSquareIcon className="w-5 stroke-white cursor-pointer" />
                  </a>
                </div>
              </Wrapper>
            )
          } else if (g.originType === BeteiligungType.COMPANY) {
            return (
              <Wrapper key={g.uuid}>
                <div className="text-white flex items-center flex-row gap-1">
                  <BuildingOffice2Icon className="stroke-white w-5" />
                  {`${g.origin.base.name}`}
                </div>
                <div className="flex gap-3 text-white items-center">
                  {`${formatEUR(g.beteiligungsHoehe)} | ${t.company.gesellschafter.stimmenPrefix}${formatNumber(g.stimmen)}`}
                  <a href={`/admin/company/${g.origin.uuid}`}>
                    <ArrowTopRightOnSquareIcon className="w-5 stroke-white cursor-pointer" />
                  </a>
                </div>
              </Wrapper>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}

export default Gesellschafter
