import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useEffect, useState } from 'react'
import Input from '../Input/Input'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import Checkbox from '../Checkbox/Checkbox'
import Loading from '../Loading/Loading'
import { Beteiligung } from '../../../types/Beteiligung'

export type Criteria = {
  originUUID?: string
}

type Props = {
  criteria?: Criteria
  selected: Beteiligung[]
  onSelect: (beteiligungList: Beteiligung[]) => void
  single?: boolean
  excludedUUIDList?: string[]
}

const BeteiligungSelect: React.FC<Props> = ({ criteria = {}, selected = [], single = false, onSelect, excludedUUIDList = [] }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [search, setSearch] = useState<string>('')
  const [beteiligungList, setBeteiligungList] = useState<Beteiligung[]>()
  const list = functional.use(Routes.BETEILIGUNG_LIST)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const pull = async () => {
      setLoading(true)
      const result: Beteiligung[] = await list({ criteria })
      setLoading(false)
      if (Array.isArray(result)) {
        setBeteiligungList(result.filter((beteiligung) => !excludedUUIDList.includes(beteiligung.uuid)))
      }
    }
    if (!beteiligungList) {
      pull()
    }
  }, [criteria, beteiligungList, list, excludedUUIDList])

  const getName = (beteiligung: Beteiligung) => {
    return `${beteiligung.targetCompany.base.name}`
  }

  const filter = (beteiligung: Beteiligung) => {
    if (search) {
      return getName(beteiligung).toLowerCase().includes(search.toLowerCase())
    }
    return true
  }

  const isSelected = (beteiligung: Beteiligung) => !!selected.find((p) => p.uuid === beteiligung.uuid)

  const select = (beteiligung: Beteiligung) => () => {
    if (isSelected(beteiligung)) {
      onSelect(selected.filter((p) => p.uuid !== beteiligung.uuid))
    } else {
      if (single) {
        onSelect([beteiligung])
      } else {
        onSelect([...selected, beteiligung])
      }
    }
  }

  return (
    <div className="w-full flex flex-col gap-1">
      <Input label placeholder={t.UI.beteiligungSelect.search} value={search} onChange={setSearch} />
      <div className="flex flex-col gap-1 max-h-32 overflow-y-auto overflow-x-hidden">
        {!loading &&
          Array.isArray(beteiligungList) &&
          beteiligungList
            .filter(filter)
            .map((beteiligung) => (
              <Checkbox key={beteiligung.uuid} label={getName(beteiligung)} checked={isSelected(beteiligung)} onChange={select(beteiligung)} />
            ))}
        <Loading loading={!loading} />
      </div>
    </div>
  )
}

export default BeteiligungSelect
