import { Beteiligung, BeteiligungType } from '../../../../../../../types/Beteiligung'
import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import Toggle from '../../../../../../UI/Toggle/Toggle'
import useToggleAnwesend from '../../hooks/useToggleAnwesend'
import useSetAbstimmung from '../../hooks/useSetAbstimmung'
import Toggles from './Toggles/Toggles'

type Props = {
  beteiligungen: Beteiligung[]
  anwesendOnly?: boolean
}

const Voting: React.FC<Props> = ({ beteiligungen, anwesendOnly = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toggleAnwesend = useToggleAnwesend(beteiligungen)
  const setAbstimmung = useSetAbstimmung(beteiligungen)

  if (!toggleAnwesend || !setAbstimmung) return <div></div>
  const { toggle, isAnwesend } = toggleAnwesend
  const { set, abstimmung } = setAbstimmung

  const getName = () => {
    const b = beteiligungen[0]
    if (b.originType === BeteiligungType.PERSON) {
      return `${b.origin.kontakt.vorname} ${b.origin.kontakt.nachname}`
    } else if (b.originType === BeteiligungType.COMPANY) {
      return b.origin.base.name
    }
  }

  return (
    <div className="flex flex-row gap-2 items-center justify-between">
      <div className={anwesendOnly ? 'flex w-full flex-row gap-2 justify-between' : ''}>
        <div className="truncate">{getName()}</div>
        <Toggle label={t.versammlung.moderate.votingList.anwesend.toggle} value={isAnwesend} onChange={toggle} />
      </div>
      {!anwesendOnly && isAnwesend && <Toggles id={beteiligungen[0].uuid} abstimmung={abstimmung} set={set} />}
    </div>
  )
}

export default Voting
