import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import { SectionType } from '../types'
import useToast, { Type } from '../../../../../../hooks/useToast'
import props from '../../../../../../../redux/props'
import Routes from '../../../../../../../redux/routes'
import Form from '../../../../../../UI/Form/Form'
import Input from '../../../../../../UI/Input/Input'
import Button from '../../../../../../UI/Button/Button'
import ManualFreigabe from './ManualFreigabe'
import { Versammlung } from '../../../../../../../types/Versammlung/Versammlung'
import ManualPersonList from '../../../../../../UI/ManualPersonList/ManualPersonList'

const Freigabe: React.FC<SectionType> = ({ company, data, set }) => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const [testMail, setTestMail] = useState<string>('')
  const versammlungTestMail = functional.use(Routes.VERSAMMLUNG_TEST_MAIL)
  const sendFreigabeMail = functional.use(Routes.VERSAMMLUNG_SEND_FREIGABE_MAIL)
  const sendManualFreigabe = functional.use(Routes.VERSAMMLUNG_TRIGGER_MANUAL_FREIGABE)

  useEffect(() => {
    if (!Array.isArray(data.freigabe?.empfaenger)) {
      const freigabe: Versammlung['freigabe'] = {
        ...data.freigabe,
        empfaenger: [],
      }
      set('freigabe')(freigabe)
    }
  }, [data, set])

  const sendTestMail = async () => {
    const status = await versammlungTestMail({ receiver: testMail, data })
    if (status) {
      setLocal('testMailVerschickt')(true)
      toast(t.versammlung.upsert.sendTestMail.success, Type.SUCCESS)
    } else {
      toast(t.versammlung.upsert.sendTestMail.error, Type.ERROR)
    }
  }

  const sendFreigabe = async () => {
    const status = await sendFreigabeMail({ data })
    if (status) {
      set('freigabe')({ ...data.freigabe, sendFreigabeMail: true, status: false })
      toast(t.versammlung.upsert.freigabe.send.success, Type.SUCCESS)
    } else {
      toast(t.versammlung.upsert.freigabe.send.error, Type.ERROR)
    }
  }

  const triggerManualFreigabe = async (message: string) => {
    const manualData = await sendManualFreigabe({ data, message })
    if (manualData) {
      set('freigabe')({ ...data.freigabe, status: true, manual: manualData })
      toast(t.versammlung.upsert.freigabe.manual.success, Type.SUCCESS)
    } else {
      toast(t.versammlung.upsert.freigabe.manual.error, Type.ERROR)
    }
  }

  const setLocal = (key: keyof Versammlung['freigabe']) => (value: any) => {
    set('freigabe')({ ...data.freigabe, [key]: value })
  }

  return (
    <div>
      <div className="flex flex-col lg:flex-row gap-3">
        <div className="flex-1 flex flex-col gap-2">
          <Form onSubmit={sendTestMail}>
            {!data.freigabe.testMailVerschickt && <div className="text-sm">{t.versammlung.upsert.sendTestMail.hint}</div>}
            <Input type="email" required label placeholder={t.versammlung.attributes.testMail} value={testMail} onChange={setTestMail} />
            <div className="flex justify-end">
              <Button type="submit" text={t.versammlung.upsert.sendTestMail.cta} className="mt-2" />
            </div>
          </Form>
          {data.freigabe.testMailVerschickt && (
            <>
              {data.freigabe?.manual?.person ? (
                <div className="text-sm text-red text-left">
                  {t.versammlung.upsert.freigabe.manual.alreadyPrefix}
                  {`${data.freigabe.manual.person.kontakt.vorname} ${data.freigabe.manual.person.kontakt.nachname}`}
                  {t.versammlung.upsert.freigabe.manual.alreadySuffix}
                  {data.freigabe.manual.message}
                </div>
              ) : (
                <>
                  <ManualPersonList data={data.freigabe.empfaenger} set={setLocal('empfaenger')} sendFreigabeMail={sendFreigabe} />
                  {data.freigabe.sendFreigabeMail && (
                    <div className="text-sm text-red text-right">{t.versammlung.upsert.freigabe.send.alreadyHint}</div>
                  )}
                </>
              )}
              {!data.freigabe.status && <ManualFreigabe callback={triggerManualFreigabe} />}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Freigabe
