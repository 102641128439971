import { ArrowRightCircleIcon, PlayCircleIcon } from '@heroicons/react/24/solid'
import useNavigation from '../../hooks/useNavigation'
import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import { useParams } from 'react-router-dom'
import { ModerationState, moderationStateProp } from '../../../../../../../types/Versammlung/Moderation'

const Next = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const { type } = useParams()
  const navigation = useNavigation()
  const moderationState: ModerationState = useSelector((state) => state[moderationStateProp])
  const { next } = navigation

  const wasStarted = !!moderationState?.versammlungErgebnis?.startDate

  const start =
    (isLiveMode = false) =>
    () => {
      if (isLiveMode) {
        next(new Date())
      } else {
        next()
      }
    }

  return (
    <div>
      <div className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">
        {t.versammlung.moderate.next.title(t.versammlung[type])}
      </div>
      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-3 items-center">
          <PlayCircleIcon className="fill-blue w-48 cursor-pointer" onClick={start(true)} />
          <div className="text-blue text-lg text-center">{t.versammlung.moderate.next.start(t.versammlung[type])}</div>
        </div>
        {!wasStarted ? (
          <div className="flex flex-col gap-0 items-center">
            <ArrowRightCircleIcon className="fill-turquoise w-32 cursor-pointer" onClick={start()} />
            <div className="text-center text-turquoise">{t.versammlung.moderate.next.demoStart}</div>
            <div className="text-center text-turquoise whitespace-pre-wrap leading-5">
              {t.versammlung.moderate.next.demoStartHint(t.versammlung[type])}
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-0 items-center">
            <ArrowRightCircleIcon className="fill-turquoise w-32 cursor-not-allowed" />
            <div className="text-turquoise whitespace-pre-wrap leading-5 text-center">
              {t.versammlung.moderate.next.demoAlreadyStarted(t.versammlung[type])}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Next
