import Input from '../../../../UI/Input/Input'
import Country from '../../../../UI/Input/Country'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import Onliner from '../../../../UI/Input/Onliner'
import { Kontakt as KontaktType, Ansprache } from '../../../../../types/Person'
import Dropdown from '../../../../UI/Dropdown/Dropdown'

const ContactInformation: React.FC<SectionProps<KontaktType>> = ({ data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const isReadOnly = !set

  const internalSet = (key: keyof KontaktType) => (value: any) => {
    if (!isReadOnly) {
      const newData = { ...data, [key]: value }
      set(newData)
    }
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.titel} value={data['titel']} onChange={internalSet('titel')} />
      </InputWrapper>
      <InputWrapper>
        <Dropdown
          label={t.attributes.ansprache}
          required
          value={data['ansprache']}
          items={Object.keys(Ansprache).map((s) => ({ label: t.attributes.anspracheItems[s], value: s }))}
          onChange={internalSet('ansprache')}
          disabled={isReadOnly}
        />
      </InputWrapper>
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.vorname} value={data['vorname']} onChange={internalSet('vorname')} />
      </InputWrapper>
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.nachname} value={data['nachname']} onChange={internalSet('nachname')} />
      </InputWrapper>
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.geburtsname} value={data['geburtsname']} onChange={internalSet('geburtsname')} />
      </InputWrapper>
      <InputWrapper>
        <Input
          disabled={isReadOnly}
          type="date"
          label
          placeholder={t.attributes.geburtsdatum}
          value={data['geburtsdatum']}
          onChange={internalSet('geburtsdatum')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          disabled={isReadOnly}
          label
          placeholder={t.attributes.strasseHausnummer}
          value={data['strasseHausnummer']}
          onChange={internalSet('strasseHausnummer')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          disabled={isReadOnly}
          label
          placeholder={t.attributes.postleitzahl}
          value={data['postleitzahl']}
          onChange={internalSet('postleitzahl')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.stadt} value={data['stadt']} onChange={internalSet('stadt')} />
      </InputWrapper>
      <InputWrapper>
        <Country disabled={isReadOnly} value={data['land']} onChange={internalSet('land')} />
      </InputWrapper>
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.telefon} value={data['telefon']} onChange={internalSet('telefon')} />
      </InputWrapper>
      <InputWrapper>
        <Input
          disabled={isReadOnly}
          label
          placeholder={t.attributes.mobilTelefon}
          value={data['mobilTelefon']}
          onChange={internalSet('mobilTelefon')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input type="email" disabled={isReadOnly} label placeholder={t.attributes.mail} value={data['mail']} onChange={internalSet('mail')} />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="email"
          disabled={isReadOnly}
          label
          placeholder={t.attributes.secondaryMail}
          value={data['secondaryMail']}
          onChange={internalSet('secondaryMail')}
        />
      </InputWrapper>
      <InputWrapper>
        <Onliner disabled={isReadOnly} value={data['onliner']} onChange={internalSet('onliner')} />
      </InputWrapper>
      {/* <InputWrapper>Steuerberater</InputWrapper>
      <InputWrapper>Vormund</InputWrapper> */}
    </div>
  )
}

export default ContactInformation
