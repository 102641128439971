import { Beteiligung, BeteiligungType } from './Beteiligung'
import { Company } from './Company'
import { Person } from './Person'
import { File } from './generic'

export enum UebertragungArt {
  ERBFALL = 'ERBFALL',
  UNTER_LEBENDEN_ENTGELDLICH = 'UNTER_LEBENDEN_ENTGELDLICH',
  UNTER_LEBENDEN_UNENTGELDLICH = 'UNTER_LEBENDEN_UNENTGELDLICH',
}

export type Uebertragung<Origin extends Company | Person = any, Target extends Company | Person = any> = {
  uuid?: string
  // uuid of the beteiligung that is transferred
  sourceBeteiligungUUID: string
  // company or person uuid from which the beteiligung is transferred
  originType: BeteiligungType
  originUUID?: string
  // company or person uuid to which the beteiligung is transferred
  targetType: BeteiligungType
  targetUUID?: string

  beteiligungsHoehe?: number
  stimmen?: number
  anfangszeitpunkt?: Date
  files?: File[]
  art?: UebertragungArt

  // dynamic properties
  sourceBeteiligung?: Beteiligung<Origin>
  origin?: Origin
  target?: Target
}
