import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import { Company } from '../../../../../../../types/Company'
import Textarea from '../../../../../../UI/Textarea/Textarea'
import AddFiles from '../../../../../../UI/AddFiles/AddFiles'
import { File } from '../../../../../../../types/generic'
import { UPLOAD_TYPES } from '../../../../../../UI/DirectFileUpload/DirectFileUpload'
import Dropdown from '../../../../../../UI/Dropdown/Dropdown'
import { getMehrheitName } from '../../../../../../../utility'
import VotingOptions from './VotingOptions'
import { Tagesordnungspunkt, TagesordnungspunktType } from '../../../../../../../types/Versammlung/Versammlung'
import RemoveConfirmation from '../../../../../../UI/RemoveConfirmation/RemoveConfirmation'

type Props = {
  company: Company
  item: Tagesordnungspunkt
  set: (key: keyof Tagesordnungspunkt) => (value: any) => void
  remove: () => void
}

const Single: React.FC<Props> = ({ company, item, set, remove }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const addFiles = (files: File[]) => {
    const newFiles = Array.isArray(item.files) ? [...item.files, ...files] : files
    set('files')(newFiles)
  }

  const removeFile = (file: File) => () => {
    const newFiles = item.files.filter((f) => f.key !== file.key)
    set('files')(newFiles)
  }

  const mehrheitEnabled = Array.isArray(company?.mehrheit?.custom) && company.mehrheit.custom.length > 0
  const getMehrheitOptions = () =>
    company.mehrheit.custom.map((m) => ({
      label: getMehrheitName(t, m),
      value: m.name,
    }))

  const getSelectedMehrheit = () => {
    if (item.mehrheit) {
      return item.mehrheit.name
    }
    return ''
  }

  const updateMehrheit = (value: string) => {
    const selectedMehrheit = company.mehrheit.custom.find((m) => m.name === value)
    set('mehrheit')(selectedMehrheit)
  }

  return (
    <div className="flex flex-col gap-1 border-b border-turquoise pb-3">
      <div className="text-right">
        <RemoveConfirmation onConfirm={remove} text={t.versammlung.upsert.removeTOPCTAConfirmationText(item.beschreibung)}>
          <span className="text-red text-sm cursor-pointer hover:underline">{t.versammlung.upsert.removeTOPCTA}</span>
        </RemoveConfirmation>
      </div>
      <Dropdown
        label={t.versammlung.attributes.type}
        value={item.type}
        onChange={set('type')}
        items={Object.keys(TagesordnungspunktType).map((key) => ({ label: t.versammlung.attributes[key], value: key }))}
      />
      {item.type === TagesordnungspunktType.VOTING_OPTIONS && <VotingOptions options={item.votingOptions} set={set('votingOptions')} />}
      <Textarea label placeholder={t.versammlung.attributes.thema} value={item.beschreibung} onChange={set('beschreibung')} />
      {item.type !== TagesordnungspunktType.INFO && (
        <Textarea label placeholder={t.versammlung.attributes.beschlussvorlage} value={item.beschlussvorlage} onChange={set('beschlussvorlage')} />
      )}
      <Textarea label placeholder={t.versammlung.attributes.protokoll} value={item.protokoll} onChange={set('protokoll')} />
      <Textarea label placeholder={t.versammlung.attributes.notiz} value={item.notiz} onChange={set('notiz')} />
      {mehrheitEnabled && (
        <Dropdown
          label={t.versammlung.attributes.mehrheit}
          emptySelection={t.versammlung.attributes.emptySelectionMehrheit}
          value={getSelectedMehrheit()}
          onChange={updateMehrheit}
          items={getMehrheitOptions()}
        />
      )}
      <AddFiles
        id={item.key}
        removeCallback={removeFile}
        files={item.files}
        onFiles={addFiles}
        uploadType={UPLOAD_TYPES.VERSAMMLUNG_TAGESORDNUNGSPUNKT}
      />
    </div>
  )
}

export default Single
