import Input from '../../../../UI/Input/Input'
import Country from '../../../../UI/Input/Country'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import { InputWrapper, SectionProps } from '../Upsert'
import Onliner from '../../../../UI/Input/Onliner'
import CompanyLegalTypes from '../../../../UI/Input/CompanyLegalType'
import Toggle from '../../../../UI/Toggle/Toggle'
import { Base as BaseType } from '../../../../../types/Company'
import SimpleExternalPersonList from '../../../../UI/SimpleExternalPersonList/SimpleExternalPersonList'
import SimpleInternalPersonList from '../../../../UI/SimpleInternalPersonList/SimpleInternalPersonList'

const Base: React.FC<SectionProps<BaseType>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const isReadOnly = !set

  const internalSet = (key: keyof BaseType) => (value: any) => {
    if (!isReadOnly) {
      const newData = { ...data, [key]: value }
      set(newData)
    }
  }

  return (
    <div className="flex flex-row flex-wrap gap-[10px]">
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.name} value={data['name']} onChange={internalSet('name')} />
      </InputWrapper>
      <InputWrapper>
        <CompanyLegalTypes disabled={isReadOnly} value={data['rechtsform']} onChange={internalSet('rechtsform')} />
      </InputWrapper>
      <InputWrapper>
        <Input
          disabled={isReadOnly}
          label
          placeholder={t.attributes.strasseHausnummer}
          value={data['strasseHausnummer']}
          onChange={internalSet('strasseHausnummer')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          disabled={isReadOnly}
          label
          placeholder={t.attributes.postleitzahl}
          value={data['postleitzahl']}
          onChange={internalSet('postleitzahl')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.stadt} value={data['stadt']} onChange={internalSet('stadt')} />
      </InputWrapper>
      <InputWrapper>
        <Country disabled={isReadOnly} value={data['land']} onChange={internalSet('land')} />
      </InputWrapper>
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.telefon} value={data['telefon']} onChange={internalSet('telefon')} />
      </InputWrapper>
      <InputWrapper>
        <Input
          disabled={isReadOnly}
          label
          placeholder={t.attributes.mobilTelefon}
          value={data['mobilTelefon']}
          onChange={internalSet('mobilTelefon')}
        />
      </InputWrapper>
      <InputWrapper>
        <Input disabled={isReadOnly} label placeholder={t.attributes.mail} value={data['mail']} onChange={internalSet('mail')} />
      </InputWrapper>
      <InputWrapper>
        <Onliner disabled={isReadOnly} value={data['onliner']} onChange={internalSet('onliner')} />
      </InputWrapper>
      <InputWrapper>
        <SimpleExternalPersonList
          title={t.company.externalContactPerson.title}
          data={data.externalContactPersonList}
          set={internalSet('externalContactPersonList')}
          readOnly={isReadOnly}
          enableMail={true}
        />
      </InputWrapper>
      <InputWrapper>
        <SimpleInternalPersonList
          companyUUID={company.uuid}
          title={t.company.internalContactPerson.title}
          data={data.internalContactPersonList}
          set={internalSet('internalContactPersonList')}
          readOnly={isReadOnly}
          enableMail={true}
        />
      </InputWrapper>
      {!isReadOnly && (
        <div>
          <Toggle label={t.attributes.externallyManaged} value={data['externallyManaged']} onChange={internalSet('externallyManaged')} />
        </div>
      )}
    </div>
  )
}

export default Base
