import UebertragungManager from '../../../../UI/UebertragungManager/UebertragungManager'
import { SectionProps } from '../Upsert'
import { BeteiligungType } from '../../../../../types/Beteiligung'
import { UebertragungSettings } from '../../../../../types/Company'
import Number from '../../../../UI/Input/Number'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import Toggle from '../../../../UI/Toggle/Toggle'
import { UebertragungArt } from '../../../../../types/Uebertragung'
import { useEffect } from 'react'

const Uebertragungen: React.FC<SectionProps<UebertragungSettings>> = ({ company, data, set }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const internalSet = (key: keyof UebertragungSettings) => (value: any) => {
    const newData = { ...data, [key]: value }
    set(newData)
  }

  useEffect(() => {
    if (!data) {
      set({
        stimmenMax: 0,
        [UebertragungArt.ERBFALL]: false,
        [UebertragungArt.UNTER_LEBENDEN_ENTGELDLICH]: false,
        [UebertragungArt.UNTER_LEBENDEN_UNENTGELDLICH]: false,
      })
    }
  }, [data, set])

  if (!data) return null
  return (
    <div className="flex flex-col gap-5">
      <Number
        label={t.company.uebertragungSettings.stimmenMax}
        value={data.stimmenMax}
        onChange={internalSet('stimmenMax')}
        placeholder={t.company.uebertragungSettings.stimmenMax}
      />
      <div className="text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.company.uebertragungSettings.respectLimitLabel}</div>
      <div className="flex flex-col gap-2">
        <Toggle
          label={t.UI.uebertragungManager.add.art.items[UebertragungArt.ERBFALL]}
          value={data[UebertragungArt.ERBFALL]}
          onChange={internalSet(UebertragungArt.ERBFALL)}
        />
        <Toggle
          label={t.UI.uebertragungManager.add.art.items[UebertragungArt.UNTER_LEBENDEN_ENTGELDLICH]}
          value={data[UebertragungArt.UNTER_LEBENDEN_ENTGELDLICH]}
          onChange={internalSet(UebertragungArt.UNTER_LEBENDEN_ENTGELDLICH)}
        />
        <Toggle
          label={t.UI.uebertragungManager.add.art.items[UebertragungArt.UNTER_LEBENDEN_UNENTGELDLICH]}
          value={data[UebertragungArt.UNTER_LEBENDEN_UNENTGELDLICH]}
          onChange={internalSet(UebertragungArt.UNTER_LEBENDEN_UNENTGELDLICH)}
        />
      </div>
      <UebertragungManager originType={BeteiligungType.COMPANY} originUUID={company.uuid} />
    </div>
  )
}

export default Uebertragungen
