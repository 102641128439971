import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Company } from '../../../../types/Company'
import { useCallback, useEffect, useState } from 'react'
import Steuer from './Section/Steuer'
import Inactivity from './Section/Inaktivitaet'
import Accordion, { SectionItem } from '../../../UI/Accordion/Accordion'
import styled from 'styled-components'
import useStatusValidator from './useStatusValidator/useStatusValidator'
import useGesellschafterValidator from './useStatusValidator/useGesellschafterValidator'
import { ValidationProp } from './useStatusValidator/types'
import { useParams } from 'react-router-dom'
import { crud } from '@think-internet/zeus-frontend-package'
import useToast, { Type } from '../../../hooks/useToast'
import useHasChanged from '../../../hooks/useHasChanged'
import Overview from './Section/Overview'
import Base from './Section/Base'
import Kapitalisierung from './Section/Kapitalisierung'
import Kontaktpersonen from './Section/Kontaktpersonen/Kontaktpersonen'
import Logo from './Section/Logo'
import Gesellschafter from './Section/Gesellschafter'
import Bank from './Section/Bank'
import Mehrheit from './Section/Mehrheit/Mehrheit'
import Beschlussfaehigkeit from './Section/Beschlussfaehigkeit/Beschlussfaehigkeit'
import Kapitalerhoehung from './Section/Kapitalerhoehung/Kapitalerhoehung'
import Insolvenz from './Section/Insolvenz'
import Administratoren from './Section/Administratoren/Administratoren'
import Einladungsfrist from './Section/Einladungsfrist'
import Beteiligungen from './Section/Beteiligungen'
import { BuildingOffice2Icon } from '@heroicons/react/24/outline'
import Uebertragungen from './Section/Uebertragungen'
import SideNavigation from '../../../UI/Layout/SideNavigation/SideNavigation'
import MediumContainer from '../../../UI/Container/MediumContainer'
import Save from '../../../UI/Save/Save'
import useTodoRemoveAdmin from '../../Todo/hooks/useTodoRemoveAdmin'
import useTodoCreateAdmin from '../../Todo/hooks/useTodoCreateAdmin'
import { Prio, TargetType, Type as TodoType } from '../../../../types/Todo/Todo'
import { UebertragungArt } from '../../../../types/Uebertragung'

export const InputWrapper = styled.div<{ itemsPerRow?: number }>`
  width: ${({ itemsPerRow: i }) => (i ? `calc(100% / ${i} - 10px * ${i - 1} / ${i})` : 'calc(100% / 2 - 5px)')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export type SectionProps<T> = {
  company?: Company
  data?: T
  set?: (value: T) => void
}

const Upsert = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<Company>()
  const statusValidator = useStatusValidator(data)
  const isGesellschafterValid = useGesellschafterValidator(data)
  const hasChanged = useHasChanged(data)
  const { uuid } = useParams<{ uuid: string }>()
  const companyFeature = crud.use(props.COMPANY)
  const toast = useToast()
  const [hasMissingInformation, setHasMissingInformation] = useState(false)
  const removeTodo = useTodoRemoveAdmin()
  const createTodo = useTodoCreateAdmin()

  useEffect(() => {
    const base: Company = {
      base: {},
      steuer: {},
      kapitalisierung: {},
      bank: {},
      kontaktpersonen: {
        internal: [],
        external: [],
      },
      logo: {},
      mehrheit: {
        custom: [],
      },
      beschlussfaehigkeit: {
        bestimmteKomplementaerePersonUUIDList: [],
        bestimmteKomplementaereCompanyUUIDList: [],
      },
      kapitalerhoehung: {
        list: [],
      },
      insolvenz: {},
      administratoren: {
        primaer: [],
        sekundaer: [],
      },
      uebertragungSettings: {
        stimmenMax: 0,
        [UebertragungArt.ERBFALL]: false,
        [UebertragungArt.UNTER_LEBENDEN_ENTGELDLICH]: false,
        [UebertragungArt.UNTER_LEBENDEN_UNENTGELDLICH]: false,
      },
      einladungsfrist: {},
      inaktivitaet: {},
    }
    const getCompany = async () => {
      const company = await companyFeature.get({ uuid })
      if (company) {
        setData(company)
        hasChanged.init(company)
      } else {
        toast(t.company.upsert.errorLoading, Type.ERROR)
      }
    }
    if (!data) {
      if (uuid) {
        getCompany()
      } else {
        setData(base)
        hasChanged.init(base)
      }
    }
  }, [uuid, companyFeature, toast, t.company.upsert.errorLoading, hasChanged, data])

  const items = useCallback((): SectionItem[] => {
    const set =
      <T,>(key: keyof Company) =>
      (value: keyof T) =>
        setData({ ...data, [key]: value })
    const items = [
      {
        title: t.company.category.overview,
        content: <Overview company={data} />,
        id: 'overview',
      },
      // {
      //   title: t.company.category.loginInformation,
      //   content: <LoginInformation data={data} set={set} />,
      //   status: statusValidator(ValidationProp.LOGIN_INFORMATION),
      // },
      {
        title: t.company.category.base,
        content: <Base company={data} data={data.base} set={set('base')} />,
        status: statusValidator(ValidationProp.BASE),
        id: 'base',
      },
      {
        title: t.company.category.steuer,
        content: <Steuer data={data.steuer} set={set('steuer')} />,
        status: statusValidator(ValidationProp.STEUER),
        id: 'steuer',
      },
      {
        title: t.company.category.kapitalisierung,
        content: <Kapitalisierung data={data.kapitalisierung} set={set('kapitalisierung')} />,
        status: statusValidator(ValidationProp.KAPITALISIERUNG),
        id: 'kapitalisierung',
      },
      {
        title: t.company.category.bank,
        content: <Bank data={data.bank} set={set('bank')} />,
        status: statusValidator(ValidationProp.BANK),
        id: 'bank',
      },
      {
        title: t.company.category.kontaktpersonen,
        content: <Kontaktpersonen company={data} data={data.kontaktpersonen} set={set('kontaktpersonen')} />,
        id: 'kontaktpersonen',
      },
      {
        title: t.company.category.logo,
        content: <Logo company={data} data={data.logo} set={set('logo')} />,
        status: statusValidator(ValidationProp.LOGO),
        id: 'logo',
      },
    ]
    if (!!data.uuid) {
      items.push(
        {
          title: t.company.category.gesellschafter,
          content: <Gesellschafter company={data} />,
          status: isGesellschafterValid,
          id: 'gesellschafter',
        },
        {
          title: t.company.category.beteiligungen,
          content: <Beteiligungen company={data} data={null} set={null} />,
          id: 'beteiligungen',
        },
        {
          title: t.company.category.mehrheiten,
          content: <Mehrheit data={data.mehrheit} set={set('mehrheit')} />,
          status: statusValidator(ValidationProp.MEHRHEIT),
          id: 'mehrheiten',
        },
        {
          title: t.company.category.beschlussfaehigkeit,
          content: <Beschlussfaehigkeit company={data} data={data.beschlussfaehigkeit} set={set('beschlussfaehigkeit')} />,
          id: 'beschlussfaehigkeit',
        },
        {
          title: t.company.category.kapitalerhoehung,
          content: <Kapitalerhoehung company={data} data={data.kapitalerhoehung} set={set('kapitalerhoehung')} />,
          id: 'kapitalerhoehung',
        },
        {
          title: t.company.category.insolvenz,
          content: <Insolvenz company={data} data={data.insolvenz} set={set('insolvenz')} />,
          id: 'insolvenz',
        },
        {
          title: t.company.category.administratoren,
          content: <Administratoren data={data.administratoren} set={set('administratoren')} />,
          status: statusValidator(ValidationProp.ADMINISTRATOREN),
          id: 'administratoren',
        },
        {
          title: t.company.category.uebertragung,
          content: <Uebertragungen company={data} data={data.uebertragungSettings} set={set('uebertragungSettings')} />,
          id: 'uebertragung',
        },
        {
          title: t.company.category.einladungsfrist,
          content: <Einladungsfrist data={data.einladungsfrist} set={set('einladungsfrist')} />,
          status: statusValidator(ValidationProp.EINLADUNGSFRIST),
          id: 'einladungsfrist',
        },
        {
          title: t.company.category.inaktivitaet,
          content: <Inactivity data={data.inaktivitaet} set={set('inaktivitaet')} />,
          status: statusValidator(ValidationProp.INACTIVITY),
          id: 'inaktivitaet',
        },
        // {
        //   title: t.company.category.sonderbetriebsEinnahmenAusgaben,
        //   content: <div></div>,
        // },
        // {
        //   title: t.company.category.importGUE,
        //   content: <div></div>,
        // },
      )
    }
    return items
  }, [data, statusValidator, t, isGesellschafterValid])

  useEffect(() => {
    if (data) {
      const hasMissingNeededInformation = items().reduce((acc, item) => {
        if (typeof item.status === 'boolean') {
          if (!item.status) return true
        } else if (!!item.status) {
          if (item.status.missingNEEDED.length > 0) return true
        }
        return acc
      }, false)
      if (hasMissingInformation !== hasMissingNeededInformation) {
        setHasMissingInformation(hasMissingNeededInformation)
      }
    }
  }, [data, hasMissingInformation, items])

  const savedCallback = (company: Company) => {
    setData(company)
    hasChanged.init(company)
    if (hasMissingInformation) {
      createTodo(TodoType.STAMMDATEN, TargetType.COMPANY, Prio.HIGH, {
        uuid: company.uuid,
      })
    } else {
      removeTodo(TodoType.STAMMDATEN, TargetType.COMPANY, company.uuid)
    }
    toast(t.company.upsert.success, Type.SUCCESS)
  }

  if (!data) return null
  return (
    <div className="flex flex-col gap-1 mt-10 pb-20">
      <MediumContainer>
        <div className="flex gap-3 items-center">
          <BuildingOffice2Icon className="fill-blue w-10" />
          {!!data.uuid && <div className="font-bold text-lg text-blue">{t.company.upsert.updateTitle(data)}</div>}
          {!data.uuid && <div className="font-bold text-lg text-blue">{t.company.upsert.createTitle}</div>}
        </div>
      </MediumContainer>
      <MediumContainer>
        <div className="relative mt-3">
          <SideNavigation uuid={data.uuid} items={t.company.navigation} />
          <Accordion items={items()} initIndex={0} />
        </div>
      </MediumContainer>
      <Save
        data={data}
        upsert={companyFeature.upsert}
        hasChanged={hasChanged.hasChanged}
        successCallback={savedCallback}
        cancelLink="/admin/company"
      />
    </div>
  )
}

export default Upsert
