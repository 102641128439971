import { useEffect, useState } from 'react'
import { Company } from '../../types/Company'
import props from '../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'

const useGetCompany = (companyUUID: string): Company | false => {
  const [company, setCompany] = useState<Company | false>(null)
  const companyFeature = crud.use(props.COMPANY)

  useEffect(() => {
    const pull = async () => {
      const company = await companyFeature.get({ uuid: companyUUID })
      if (company) {
        setCompany(company)
      } else {
        setCompany(false)
      }
    }
    if (!!companyUUID && (company === null || (!!company && company.uuid !== companyUUID))) {
      pull()
    }
  }, [companyUUID, company, companyFeature])

  return company
}

export default useGetCompany
