import { useEffect, useState } from 'react'
import props from '../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { Person } from '../../types/Person'

const useGetPerson = (personUUID: string): Person | false => {
  const [person, setPerson] = useState<Person | false>(null)
  const personFeature = crud.use(props.PERSON)

  useEffect(() => {
    const pull = async () => {
      const person = await personFeature.get({ uuid: personUUID })
      if (person) {
        setPerson(person)
      } else {
        setPerson(false)
      }
    }
    if (!!personUUID && (person === null || (!!person && person.uuid !== personUUID))) {
      pull()
    }
  }, [personUUID, person, personFeature])

  return person
}

export default useGetPerson
