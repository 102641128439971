enum Routes {
  // generic routes
  GET_FILE_UPLOAD_PRESIGNED_URL = 'GET_FILE_UPLOAD_PRESIGNED_URL',
  COMPANY_CREATE = 'COMPANY_CREATE',
  COMPANY_LIST = 'COMPANY_LIST',
  PERSON_CREATE = 'PERSON_CREATE',
  PERSON_LIST = 'PERSON_LIST',
  BETEILIGUNG_LIST = 'BETEILIGUNG_LIST',
  UEBERTRAGUNG_CREATE = 'UEBERTRAGUNG_CREATE',

  // versammlung routes
  VERSAMMLUNG_TEST_MAIL = 'VERSAMMLUNG_TEST_MAIL',
  VERSAMMLUNG_SEND_FREIGABE_MAIL = 'VERSAMMLUNG_SEND_FREIGABE_MAIL',
  VERSAMMLUNG_TRIGGER_MANUAL_FREIGABE = 'VERSAMMLUNG_TRIGGER_MANUAL_FREIGABE',
  VERSAMMLUNG_SAVE = 'VERSAMMLUNG_SAVE',
  VERSAMMLUNG_SAVE_END_DATE = 'VERSAMMLUNG_SAVE_END_DATE',
  VERSAMMLUNG_FINALIZE = 'VERSAMMLUNG_FINALIZE',
  VERSAMMLUNG_GET_PROTOCOL = 'VERSAMMLUNG_GET_PROTOCOL',
  VERSAMMLUNG_UPDATE_UNTERSCHRIFTEN_PERSON_LIST = 'VERSAMMLUNG_UPDATE_UNTERSCHRIFTEN_PERSON_LIST',
  VERSAMMLUNG_UPDATE_BESONDERE_ANWESENDE_PERSON_LIST = 'VERSAMMLUNG_UPDATE_BESONDERE_ANWESENDE_PERSON_LIST',
  VERSAMMLUNG_START_UNTERSCHRIFTEN_PROCESS = 'VERSAMMLUNG_START_UNTERSCHRIFTEN_PROCESS',
  VERSAMMLUNG_VALIDATE_SIGNATURE = 'VERSAMMLUNG_VALIDATE_SIGNATURE',
  VERSAMMLUNG_SIGNATURE_FEEDBACK = 'VERSAMMLUNG_SIGNATURE_FEEDBACK',
  VERSAMMLUNG_PROTOCOL_BROADCAST = 'VERSAMMLUNG_PROTOCOL_BROADCAST',
  VERSAMMLUNG_COPY = 'VERSAMMLUNG_COPY',
  VERSAMMLUNG_FREIGABE_FEEDBACK = 'VERSAMMLUNG_FREIGABE_FEEDBACK',

  // todo routes
  TODO_LIST_ADMIN = 'TODO_LIST_ADMIN',
  TODO_CREATE_ADMIN = 'TODO_CREATE_ADMIN',
  TODO_REMOVE_ADMIN = 'TODO_REMOVE_ADMIN',

  // storage routes
  STORAGE_LIST_ITEMS = 'STORAGE_LIST_ITEMS',
  STORAGE_GET_PRESIGNED_UPLOAD_URL = 'STORAGE_GET_PRESIGNED_UPLOAD_URL',
}

export default Routes
