import { useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import { useState } from 'react'
import Modal from '../../../../../../UI/Modal/Modal'
import Button from '../../../../../../UI/Button/Button'
import Textarea from '../../../../../../UI/Textarea/Textarea'

type Props = {
  callback: (message: string) => void
}

const ManualFreigabe: React.FC<Props> = ({ callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const toggle = () => setOpen(!open)

  const trigger = () => {
    callback(message)
    toggle()
  }

  return (
    <div className="border-t border-gray flex justify-end">
      <Button text={t.versammlung.upsert.freigabe.manual.cta} onClick={toggle} className="mt-2" />
      <Modal onConfirm={trigger} show={open} onClose={toggle} title={t.versammlung.upsert.freigabe.manual.title}>
        {t.versammlung.upsert.freigabe.manual.hint}
        <Textarea required className="mt-3" value={message} onChange={setMessage} placeholder={t.versammlung.upsert.freigabe.manual.placeholder} />
      </Modal>
    </div>
  )
}

export default ManualFreigabe
