import { useEffect, useState } from 'react'
import props from '../../redux/props'
import { crud } from '@think-internet/zeus-frontend-package'
import { Beteiligung } from '../../types/Beteiligung'
import { Person } from '../../types/Person'
import { Company } from '../../types/Company'

type Query = Omit<Beteiligung<Company | Person>, 'originType' | 'anfangszeitpunkt'> & { anfangszeitpunkt?: { $lte: Date } }

const useGetBeteiligung = <T extends Company | Person>(query: Query): Beteiligung<T>[] | false => {
  const [beteiligungen, setBeteiligungen] = useState<Beteiligung<T>[] | false>(null)
  const beteiligungFeature = crud.use(props.BETEILIGUNG)

  useEffect(() => {
    const pull = async () => {
      const beteiligungen = await beteiligungFeature.list(query)
      if (beteiligungen) {
        setBeteiligungen(beteiligungen)
      } else {
        setBeteiligungen(false)
      }
    }
    if (beteiligungen === null) {
      pull()
    }
  }, [query, beteiligungen, beteiligungFeature])

  return beteiligungen
}

export default useGetBeteiligung
