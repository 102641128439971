import { useDispatch } from 'react-redux'
import { Beteiligung } from '../../../../../../types/Beteiligung'
import useModerationState from './useModerationState'
import { setLocal } from '../../../../../../redux/action/local'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import getErgebnis from './helper/getErgebnis'
import { moderationStateProp } from '../../../../../../types/Versammlung/Moderation'

type Result =
  | {
      toggle: () => void
      isAnwesend: boolean
    }
  | false

const useToggleAnwesend = (beteiligungen: Beteiligung[]): Result => {
  const dispatch = useDispatch()
  const moderationState = useModerationState()
  const [isAnwesend, setIsAnwesend] = useState<boolean>(false)

  useEffect(() => {
    if (!moderationState) return
    const ergebnis = getErgebnis(beteiligungen[0], moderationState)
    if (ergebnis) {
      setIsAnwesend(ergebnis?.anwesend)
    }
  }, [moderationState, beteiligungen])

  const toggle = (): void => {
    const newState = _.cloneDeep(moderationState)
    if (!newState) return
    for (const b of beteiligungen) {
      const ergebnis = getErgebnis(b, newState)
      ergebnis.anwesend = !ergebnis.anwesend
    }
    dispatch(setLocal(moderationStateProp, newState))
  }

  return { toggle, isAnwesend }
}

export default useToggleAnwesend
