import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Uebertragung } from '../../../types/Uebertragung'
import { formatEUR, formatNumber } from '../../../utility'
import { Person } from '../../../types/Person'
import { Company } from '../../../types/Company'
import { BeteiligungType } from '../../../types/Beteiligung'

type Props = {
  uebertragungen: Uebertragung<Company | Person, Company | Person>[]
}

const List: React.FC<Props> = ({ uebertragungen }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  console.log(uebertragungen)

  return (
    <div>
      <div className="flex flex-row gap-3 w-full">
        <label className="w-4/12 grow-0 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.UI.uebertragungManager.list.from}</label>
        <label className="w-4/12 grow-0 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.UI.uebertragungManager.list.to}</label>
        <label className="w-2/12 grow-0 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.beteiligungsHoehe}</label>
        <label className="w-2/12 grow-0 text-white text-sm py-1 px-2 rounded-full font-bold bg-turquoise">{t.attributes.stimmen}</label>
      </div>
      <div className="flex flex-col gap-1 divide-y divide-gray-400">
        {Array.isArray(uebertragungen) && uebertragungen.length > 0 ? (
          uebertragungen.map((uebertragung, index) => (
            <div className="flex flex-row w-full" key={index}>
              <div className="w-4/12 px-2 grow-0 truncate">{uebertragung?.sourceBeteiligung?.targetCompany?.base?.name}</div>
              <div className="w-4/12 px-2 grow-0 truncate">
                {uebertragung.targetType === BeteiligungType.COMPANY
                  ? (uebertragung.target as Company).base.name
                  : `${(uebertragung.target as Person).kontakt.vorname} ${(uebertragung.target as Person).kontakt.nachname}`}
              </div>
              <div className="w-2/12 px-2 grow-0 truncate">{formatEUR(uebertragung.beteiligungsHoehe)}</div>
              <div className="w-2/12 px-2 grow-0 truncate">{formatNumber(uebertragung.stimmen)}</div>
            </div>
          ))
        ) : (
          <div className="mt-2 text-center text-sm">{t.UI.uebertragungManager.list.empty}</div>
        )}
      </div>
    </div>
  )
}

export default List
