import { Company } from './Company'
import { File } from './generic'
import { Person } from './Person'
import { Uebertragung } from './Uebertragung'
export enum ArtDerBeteiligung {
  KOMPLEMENTAER = 'KOMPLEMENTAER',
  KOMMANDITIST = 'KOMMANDITIST',
  GESELLSCHAFTER = 'GESELLSCHAFTER',
  MITTELBAR_BETEILIGTER = 'MITTELBAR_BETEILIGTER',
}

export enum Position {
  GESCHAEFTSFUEHRER = 'GESCHAEFTSFUEHRER',
  BEIRAT = 'BEIRAT',
  UNDEFINED = 'UNDEFINED',
}

export enum BeteiligungType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}

export type Beteiligung<T extends Company | Person = any> = {
  uuid?: string
  originType: BeteiligungType
  originUUID?: string
  targetCompanyUUID?: string
  beteiligungsHoehe?: number
  stimmen?: number
  beteiligungsnummer?: string
  iban?: string
  bic?: string
  artDerBeteiligung?: ArtDerBeteiligung
  position?: Position
  anfangszeitpunkt?: Date
  entnahmeGesperrt?: boolean
  files?: File[]

  // dynamic properties
  origin?: T
  targetCompany?: Company
  uebertragungen?: Uebertragung[]
}
