import { Position } from '../Beteiligung'
import { Company } from '../Company'
import { Mehrheit } from '../Mehrheit'
import { Person } from '../Person'
import { File } from '../generic'
import { VersammlungErgebnis } from './VersammlungErgebnis'

export enum Type {
  gesellschafterversammlung = 'gesellschafterversammlung',
  informationsveranstaltung = 'informationsveranstaltung',
  beiratsversammlung = 'beiratsversammlung',
  treugeberversammlung = 'treugeberversammlung',
  schriftliches_abstimmungsverfahren = 'schriftliches_abstimmungsverfahren',
}

export enum StandortType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum TagesordnungspunktType {
  INFO = 'INFO',
  VOTING_DEFAULT = 'VOTING_DEFAULT',
  VOTING_OPTIONS = 'VOTING_OPTIONS',
}

export type VotingOption = {
  uuid: string
  name: string
}

export type Tagesordnungspunkt = {
  key: string
  type?: TagesordnungspunktType
  votingOptions?: VotingOption[]
  beschreibung?: string
  beschlussvorlage?: string
  protokoll?: string
  notiz?: string
  mehrheit?: Mehrheit
  files?: File[]
}

export type Einladung = {
  betreff?: string
  anschreiben?: string
}

export enum FreigabeState {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export type FreigabeEmpfaenger = {
  uuid: string
  vorname?: string
  nachname?: string
  mail?: string
  position?: Position
  status?: FreigabeState
}

export type Freigabe = {
  empfaenger?: FreigabeEmpfaenger[]
  sendFreigabeMail?: boolean
  testMailVerschickt?: boolean
  status?: boolean
  manual?: {
    personUUID: string
    message: string
    // dynamic properties
    person?: Person
  }
}

export type Versammlung = {
  uuid?: string
  companyUUID?: string
  type?: Type
  datumUhrzeit?: Date
  standortType?: StandortType[]
  einladungsfristIgnorieren?: boolean
  wirdExternProtokolliert?: boolean
  abgabefrist?: Date
  standort?: string
  meetingLink?: string
  meetingID?: string
  meetingPassword?: string
  tagesordnungspunkte?: Tagesordnungspunkt[]
  einladung?: Einladung
  freigabe?: Freigabe
  isArchived?: boolean

  // dynamic properties
  company?: Company
  ergebnis?: VersammlungErgebnis
}
