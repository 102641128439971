import { useSelector } from 'react-redux'
import { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import props from '../../../redux/props'
import Button from '../Button/Button'
import Input from '../Input/Input'
import Modal from '../Modal/Modal'
import { getUUID } from '../../../utility'
import Dropdown from '../Dropdown/Dropdown'
import { Ansprache, SimplePerson } from '../../../types/Person'

type Props = {
  title: string
  hint?: string
  data: SimplePerson[]
  set: (list: SimplePerson[]) => void
  readOnly?: boolean
  enableMail?: boolean
}

const SimpleExternalPersonList: React.FC<Props> = ({ title, hint, data = [], set, readOnly = false, enableMail = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [newPerson, setNewPerson] = useState<SimplePerson>({ uuid: getUUID() } as SimplePerson)
  const [open, setOpen] = useState<boolean>(false)

  const setProp = (prop: keyof SimplePerson) => (value: string) => setNewPerson({ ...newPerson, [prop]: value })

  const confirm = () => {
    set([...data, newPerson])
    setNewPerson({ uuid: getUUID() } as SimplePerson)
    toggle()
  }

  const getName = (person: SimplePerson) =>
    `${t.attributes.anspracheItems[person.ansprache]} ${person.vorname || ''} ${person.nachname || ''} - ${person.position}`

  const remove = (index: number) => () => {
    set(data.filter((_, i) => i !== index))
  }

  const toggle = () => setOpen(!open)

  const getAnspracheItems = () => {
    return Object.keys(Ansprache).map((a) => ({
      label: t.attributes.anspracheItems[a],
      value: a,
    }))
  }

  return (
    <div>
      <label className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">{title}</label>
      {!!hint && <div className="text-sm">{hint}</div>}
      <div className="flex flex-col gap-1 mt-3">
        {data.map((person, i) => (
          <div key={i} className="flex flex-row justify-between gap-1 items-center">
            <div>
              <div className="truncate">{getName(person)}</div>
              {enableMail && <div className="truncate">{person.mail}</div>}
            </div>
            <div className="flex flex-row gap-3">
              <div className="cursor-pointer" onClick={remove(i)}>
                <XMarkIcon className="w-6" />
              </div>
            </div>
          </div>
        ))}
      </div>
      {!readOnly && (
        <div className="flex justify-end gap-3 pt-2">
          <Button small text={t.UI.simpleExternalPersonList.add} onClick={toggle} />
        </div>
      )}
      <Modal onConfirm={confirm} show={open} onClose={toggle} title={title}>
        <div className="flex flex-col gap-2">
          <Dropdown required label={t.attributes.ansprache} value={newPerson.ansprache} onChange={setProp('ansprache')} items={getAnspracheItems()} />
          <Input required label placeholder={t.attributes.vorname} value={newPerson.vorname} onChange={setProp('vorname')} />
          <Input required label placeholder={t.attributes.nachname} value={newPerson.nachname} onChange={setProp('nachname')} />
          <Input required label placeholder={t.attributes.position} value={newPerson.position} onChange={setProp('position')} />
          {enableMail && <Input required label placeholder={t.attributes.mail} value={newPerson.mail} onChange={setProp('mail')} />}
        </div>
      </Modal>
    </div>
  )
}

export default SimpleExternalPersonList
