import { useSelector } from 'react-redux'
import { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { FreigabeEmpfaenger, FreigabeState } from '../../../types/Versammlung/Versammlung'
import props from '../../../redux/props'
import FreigabeStatusIndicator from '../FreigabeStatusIndicator/FreigabeStatusIndicator'
import Button from '../Button/Button'
import Input from '../Input/Input'
import Modal from '../Modal/Modal'
import Position from '../Input/Position'
import { getUUID } from '../../../utility'

type Props = {
  data: FreigabeEmpfaenger[]
  set: (list: FreigabeEmpfaenger[]) => void
  sendFreigabeMail?: () => void
}

const ManualPersonList: React.FC<Props> = ({ data = [], set, sendFreigabeMail }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [newPerson, setNewPerson] = useState<FreigabeEmpfaenger>({ status: FreigabeState.PENDING, uuid: getUUID() })
  const [open, setOpen] = useState<boolean>(false)
  const [openSend, setOpenSend] = useState<boolean>(false)

  const setProp = (prop: keyof FreigabeEmpfaenger) => (value: string) => setNewPerson({ ...newPerson, [prop]: value })

  const confirm = () => {
    set([...data, newPerson])
    setNewPerson({ status: FreigabeState.PENDING, uuid: getUUID() })
    toggle()
  }

  const getName = (person: FreigabeEmpfaenger) =>
    `${person.vorname || ''} ${person.nachname || ''} - ${person.mail || ''} - ${t.attributes.positionItems[person.position] || ''}`

  const remove = (index: number) => () => {
    set(data.filter((_, i) => i !== index))
  }

  const toggle = () => setOpen(!open)
  const toggleSend = () => setOpenSend(!openSend)

  const send = async () => {
    toggleSend()
    sendFreigabeMail()
  }

  return (
    <div>
      <label className="text-white text-sm py-1 px-2 rounded-full block font-bold bg-turquoise w-full">
        {t.versammlung.upsert.freigabe.empfaenger.title}
      </label>
      <div className="flex flex-col gap-1 mt-3">
        {data.map((person, i) => (
          <div key={i} className="flex flex-row justify-between gap-1 items-center">
            <div className="truncate">{getName(person)}</div>
            <div className="flex flex-row gap-3">
              {!!sendFreigabeMail && <FreigabeStatusIndicator status={person.status} />}
              <div className="cursor-pointer" onClick={remove(i)}>
                <XMarkIcon className="w-6" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end gap-3 pt-2">
        <Button text={t.versammlung.upsert.freigabe.empfaenger.addCTA} onClick={toggle} />
        {data.length > 0 && !!sendFreigabeMail && <Button text={t.versammlung.upsert.freigabe.send.cta} onClick={toggleSend} />}
      </div>
      <Modal onConfirm={confirm} show={open} onClose={toggle} title={t.versammlung.upsert.freigabe.empfaenger.title}>
        <div className="flex flex-col gap-2">
          <Input required label placeholder={t.attributes.vorname} value={newPerson.vorname} onChange={setProp('vorname')} />
          <Input required label placeholder={t.attributes.nachname} value={newPerson.nachname} onChange={setProp('nachname')} />
          <Input required type="email" label placeholder={t.attributes.mail} value={newPerson.mail} onChange={setProp('mail')} />
          <Position value={newPerson.position} onChange={setProp('position')} />
        </div>
      </Modal>
      <Modal onConfirm={send} show={openSend} onClose={toggleSend} title={t.versammlung.upsert.freigabe.send.title}>
        {t.versammlung.upsert.freigabe.send.hint}
      </Modal>
    </div>
  )
}

export default ManualPersonList
